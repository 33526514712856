@charset "UTF-8";

@font-face {
  font-family: 'Socicon';
   src:  url('./fonts/socicon.eot');
  src:  url('./fonts/socicon.eot?#iefix') format('embedded-opentype'),
    url('./fonts/socicon.woff2') format('woff2'),
    url('./fonts/socicon.ttf') format('truetype'),
    url('./fonts/socicon.woff') format('woff'),
    url('./fonts/socicon.svg#socicon') format('svg'); 
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[data-icon]:before {
  font-family: "socicon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="socicon-"], [class*=" socicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Socicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.socicon-eitaa:before {
  content: "\e97c";
}
.socicon-soroush:before {
  content: "\e97d";
}
.socicon-bale:before {
  content: "\e97e";
}
.socicon-zazzle:before {
  content: "\e97b";
}
.socicon-society6:before {
  content: "\e97a";
}
.socicon-redbubble:before {
  content: "\e979";
}
.socicon-avvo:before {
  content: "\e978";
}
.socicon-stitcher:before {
  content: "\e977";
}
.socicon-googlehangouts:before {
  content: "\e974";
}
.socicon-dlive:before {
  content: "\e975";
}
.socicon-vsco:before {
  content: "\e976";
}
.socicon-flipboard:before {
  content: "\e973";
}
.socicon-ubuntu:before {
  content: "\e958";
}
.socicon-artstation:before {
  content: "\e959";
}
.socicon-invision:before {
  content: "\e95a";
}
.socicon-torial:before {
  content: "\e95b";
}
.socicon-collectorz:before {
  content: "\e95c";
}
.socicon-seenthis:before {
  content: "\e95d";
}
.socicon-googleplaymusic:before {
  content: "\e95e";
}
.socicon-debian:before {
  content: "\e95f";
}
.socicon-filmfreeway:before {
  content: "\e960";
}
.socicon-gnome:before {
  content: "\e961";
}
.socicon-itchio:before {
  content: "\e962";
}
.socicon-jamendo:before {
  content: "\e963";
}
.socicon-mix:before {
  content: "\e964";
}
.socicon-sharepoint:before {
  content: "\e965";
}
.socicon-tinder:before {
  content: "\e966";
}
.socicon-windguru:before {
  content: "\e967";
}
.socicon-cdbaby:before {
  content: "\e968";
}
.socicon-elementaryos:before {
  content: "\e969";
}
.socicon-stage32:before {
  content: "\e96a";
}
.socicon-tiktok:before {
  content: "\e96b";
}
.socicon-gitter:before {
  content: "\e96c";
}
.socicon-letterboxd:before {
  content: "\e96d";
}
.socicon-threema:before {
  content: "\e96e";
}
.socicon-splice:before {
  content: "\e96f";
}
.socicon-metapop:before {
  content: "\e970";
}
.socicon-naver:before {
  content: "\e971";
}
.socicon-remote:before {
  content: "\e972";
}
.socicon-internet:before {
  content: "\e957";
}
.socicon-moddb:before {
  content: "\e94b";
}
.socicon-indiedb:before {
  content: "\e94c";
}
.socicon-traxsource:before {
  content: "\e94d";
}
.socicon-gamefor:before {
  content: "\e94e";
}
.socicon-pixiv:before {
  content: "\e94f";
}
.socicon-myanimelist:before {
  content: "\e950";
}
.socicon-blackberry:before {
  content: "\e951";
}
.socicon-wickr:before {
  content: "\e952";
}
.socicon-spip:before {
  content: "\e953";
}
.socicon-napster:before {
  content: "\e954";
}
.socicon-beatport:before {
  content: "\e955";
}
.socicon-hackerone:before {
  content: "\e956";
}
.socicon-hackernews:before {
  content: "\e946";
}
.socicon-smashwords:before {
  content: "\e947";
}
.socicon-kobo:before {
  content: "\e948";
}
.socicon-bookbub:before {
  content: "\e949";
}
.socicon-mailru:before {
  content: "\e94a";
}
.socicon-gitlab:before {
  content: "\e945";
}
.socicon-instructables:before {
  content: "\e944";
}
.socicon-portfolio:before {
  content: "\e943";
}
.socicon-codered:before {
  content: "\e940";
}
.socicon-origin:before {
  content: "\e941";
}
.socicon-nextdoor:before {
  content: "\e942";
}
.socicon-udemy:before {
  content: "\e93f";
}
.socicon-livemaster:before {
  content: "\e93e";
}
.socicon-crunchbase:before {
  content: "\e93b";
}
.socicon-homefy:before {
  content: "\e93c";
}
.socicon-calendly:before {
  content: "\e93d";
}
.socicon-realtor:before {
  content: "\e90f";
}
.socicon-tidal:before {
  content: "\e910";
}
.socicon-qobuz:before {
  content: "\e911";
}
.socicon-natgeo:before {
  content: "\e912";
}
.socicon-mastodon:before {
  content: "\e913";
}
.socicon-unsplash:before {
  content: "\e914";
}
.socicon-homeadvisor:before {
  content: "\e915";
}
.socicon-angieslist:before {
  content: "\e916";
}
.socicon-codepen:before {
  content: "\e917";
}
.socicon-slack:before {
  content: "\e918";
}
.socicon-openaigym:before {
  content: "\e919";
}
.socicon-logmein:before {
  content: "\e91a";
}
.socicon-fiverr:before {
  content: "\e91b";
}
.socicon-gotomeeting:before {
  content: "\e91c";
}
.socicon-aliexpress:before {
  content: "\e91d";
}
.socicon-guru:before {
  content: "\e91e";
}
.socicon-appstore:before {
  content: "\e91f";
}
.socicon-homes:before {
  content: "\e920";
}
.socicon-zoom:before {
  content: "\e921";
}
.socicon-alibaba:before {
  content: "\e922";
}
.socicon-craigslist:before {
  content: "\e923";
}
.socicon-wix:before {
  content: "\e924";
}
.socicon-redfin:before {
  content: "\e925";
}
.socicon-googlecalendar:before {
  content: "\e926";
}
.socicon-shopify:before {
  content: "\e927";
}
.socicon-freelancer:before {
  content: "\e928";
}
.socicon-seedrs:before {
  content: "\e929";
}
.socicon-bing:before {
  content: "\e92a";
}
.socicon-doodle:before {
  content: "\e92b";
}
.socicon-bonanza:before {
  content: "\e92c";
}
.socicon-squarespace:before {
  content: "\e92d";
}
.socicon-toptal:before {
  content: "\e92e";
}
.socicon-gust:before {
  content: "\e92f";
}
.socicon-ask:before {
  content: "\e930";
}
.socicon-trulia:before {
  content: "\e931";
}
.socicon-loomly:before {
  content: "\e932";
}
.socicon-ghost:before {
  content: "\e933";
}
.socicon-upwork:before {
  content: "\e934";
}
.socicon-fundable:before {
  content: "\e935";
}
.socicon-booking:before {
  content: "\e936";
}
.socicon-googlemaps:before {
  content: "\e937";
}
.socicon-zillow:before {
  content: "\e938";
}
.socicon-niconico:before {
  content: "\e939";
}
.socicon-toneden:before {
  content: "\e93a";
}
.socicon-augment:before {
  content: "\e908";
}
.socicon-bitbucket:before {
  content: "\e909";
}
.socicon-fyuse:before {
  content: "\e90a";
}
.socicon-yt-gaming:before {
  content: "\e90b";
}
.socicon-sketchfab:before {
  content: "\e90c";
}
.socicon-mobcrush:before {
  content: "\e90d";
}
.socicon-microsoft:before {
  content: "\e90e";
}
.socicon-pandora:before {
  content: "\e907";
}
.socicon-messenger:before {
  content: "\e906";
}
.socicon-gamewisp:before {
  content: "\e905";
}
.socicon-bloglovin:before {
  content: "\e904";
}
.socicon-tunein:before {
  content: "\e903";
}
.socicon-gamejolt:before {
  content: "\e901";
}
.socicon-trello:before {
  content: "\e902";
}
.socicon-spreadshirt:before {
  content: "\e900";
}
.socicon-500px:before {
  content: "\e000";
}
.socicon-8tracks:before {
  content: "\e001";
}
.socicon-airbnb:before {
  content: "\e002";
}
.socicon-alliance:before {
  content: "\e003";
}
.socicon-amazon:before {
  content: "\e004";
}
.socicon-amplement:before {
  content: "\e005";
}
.socicon-android:before {
  content: "\e006";
}
.socicon-angellist:before {
  content: "\e007";
}
.socicon-apple:before {
  content: "\e008";
}
.socicon-appnet:before {
  content: "\e009";
}
.socicon-baidu:before {
  content: "\e00a";
}
.socicon-bandcamp:before {
  content: "\e00b";
}
.socicon-battlenet:before {
  content: "\e00c";
}
.socicon-mixer:before {
  content: "\e00d";
}
.socicon-bebee:before {
  content: "\e00e";
}
.socicon-bebo:before {
  content: "\e00f";
}
.socicon-behance:before {
  content: "\e010";
}
.socicon-blizzard:before {
  content: "\e011";
}
.socicon-blogger:before {
  content: "\e012";
}
.socicon-buffer:before {
  content: "\e013";
}
.socicon-chrome:before {
  content: "\e014";
}
.socicon-coderwall:before {
  content: "\e015";
}
.socicon-curse:before {
  content: "\e016";
}
.socicon-dailymotion:before {
  content: "\e017";
}
.socicon-deezer:before {
  content: "\e018";
}
.socicon-delicious:before {
  content: "\e019";
}
.socicon-deviantart:before {
  content: "\e01a";
}
.socicon-diablo:before {
  content: "\e01b";
}
.socicon-digg:before {
  content: "\e01c";
}
.socicon-discord:before {
  content: "\e01d";
}
.socicon-disqus:before {
  content: "\e01e";
}
.socicon-douban:before {
  content: "\e01f";
}
.socicon-draugiem:before {
  content: "\e020";
}
.socicon-dribbble:before {
  content: "\e021";
}
.socicon-drupal:before {
  content: "\e022";
}
.socicon-ebay:before {
  content: "\e023";
}
.socicon-ello:before {
  content: "\e024";
}
.socicon-endomodo:before {
  content: "\e025";
}
.socicon-envato:before {
  content: "\e026";
}
.socicon-etsy:before {
  content: "\e027";
}
.socicon-facebook:before {
  content: "\e028";
}
.socicon-feedburner:before {
  content: "\e029";
}
.socicon-filmweb:before {
  content: "\e02a";
}
.socicon-firefox:before {
  content: "\e02b";
}
.socicon-flattr:before {
  content: "\e02c";
}
.socicon-flickr:before {
  content: "\e02d";
}
.socicon-formulr:before {
  content: "\e02e";
}
.socicon-forrst:before {
  content: "\e02f";
}
.socicon-foursquare:before {
  content: "\e030";
}
.socicon-friendfeed:before {
  content: "\e031";
}
.socicon-github:before {
  content: "\e032";
}
.socicon-goodreads:before {
  content: "\e033";
}
.socicon-google:before {
  content: "\e034";
}
.socicon-googlescholar:before {
  content: "\e035";
}
.socicon-googlegroups:before {
  content: "\e036";
}
.socicon-googlephotos:before {
  content: "\e037";
}
.socicon-googleplus:before {
  content: "\e038";
}
.socicon-grooveshark:before {
  content: "\e039";
}
.socicon-hackerrank:before {
  content: "\e03a";
}
.socicon-hearthstone:before {
  content: "\e03b";
}
.socicon-hellocoton:before {
  content: "\e03c";
}
.socicon-heroes:before {
  content: "\e03d";
}
.socicon-smashcast:before {
  content: "\e03e";
}
.socicon-horde:before {
  content: "\e03f";
}
.socicon-houzz:before {
  content: "\e040";
}
.socicon-icq:before {
  content: "\e041";
}
.socicon-identica:before {
  content: "\e042";
}
.socicon-imdb:before {
  content: "\e043";
}
.socicon-instagram:before {
  content: "\e044";
}
.socicon-issuu:before {
  content: "\e045";
}
.socicon-istock:before {
  content: "\e046";
}
.socicon-itunes:before {
  content: "\e047";
}
.socicon-keybase:before {
  content: "\e048";
}
.socicon-lanyrd:before {
  content: "\e049";
}
.socicon-lastfm:before {
  content: "\e04a";
}
.socicon-line:before {
  content: "\e04b";
}
.socicon-linkedin:before {
  content: "\e04c";
}
.socicon-livejournal:before {
  content: "\e04d";
}
.socicon-lyft:before {
  content: "\e04e";
}
.socicon-macos:before {
  content: "\e04f";
}
.socicon-mail:before {
  content: "\e050";
}
.socicon-medium:before {
  content: "\e051";
}
.socicon-meetup:before {
  content: "\e052";
}
.socicon-mixcloud:before {
  content: "\e053";
}
.socicon-modelmayhem:before {
  content: "\e054";
}
.socicon-mumble:before {
  content: "\e055";
}
.socicon-myspace:before {
  content: "\e056";
}
.socicon-newsvine:before {
  content: "\e057";
}
.socicon-nintendo:before {
  content: "\e058";
}
.socicon-npm:before {
  content: "\e059";
}
.socicon-odnoklassniki:before {
  content: "\e05a";
}
.socicon-openid:before {
  content: "\e05b";
}
.socicon-opera:before {
  content: "\e05c";
}
.socicon-outlook:before {
  content: "\e05d";
}
.socicon-overwatch:before {
  content: "\e05e";
}
.socicon-patreon:before {
  content: "\e05f";
}
.socicon-paypal:before {
  content: "\e060";
}
.socicon-periscope:before {
  content: "\e061";
}
.socicon-persona:before {
  content: "\e062";
}
.socicon-pinterest:before {
  content: "\e063";
}
.socicon-play:before {
  content: "\e064";
}
.socicon-player:before {
  content: "\e065";
}
.socicon-playstation:before {
  content: "\e066";
}
.socicon-pocket:before {
  content: "\e067";
}
.socicon-qq:before {
  content: "\e068";
}
.socicon-quora:before {
  content: "\e069";
}
.socicon-raidcall:before {
  content: "\e06a";
}
.socicon-ravelry:before {
  content: "\e06b";
}
.socicon-reddit:before {
  content: "\e06c";
}
.socicon-renren:before {
  content: "\e06d";
}
.socicon-researchgate:before {
  content: "\e06e";
}
.socicon-residentadvisor:before {
  content: "\e06f";
}
.socicon-reverbnation:before {
  content: "\e070";
}
.socicon-rss:before {
  content: "\e071";
}
.socicon-sharethis:before {
  content: "\e072";
}
.socicon-skype:before {
  content: "\e073";
}
.socicon-slideshare:before {
  content: "\e074";
}
.socicon-smugmug:before {
  content: "\e075";
}
.socicon-snapchat:before {
  content: "\e076";
}
.socicon-songkick:before {
  content: "\e077";
}
.socicon-soundcloud:before {
  content: "\e078";
}
.socicon-spotify:before {
  content: "\e079";
}
.socicon-stackexchange:before {
  content: "\e07a";
}
.socicon-stackoverflow:before {
  content: "\e07b";
}
.socicon-starcraft:before {
  content: "\e07c";
}
.socicon-stayfriends:before {
  content: "\e07d";
}
.socicon-steam:before {
  content: "\e07e";
}
.socicon-storehouse:before {
  content: "\e07f";
}
.socicon-strava:before {
  content: "\e080";
}
.socicon-streamjar:before {
  content: "\e081";
}
.socicon-stumbleupon:before {
  content: "\e082";
}
.socicon-swarm:before {
  content: "\e083";
}
.socicon-teamspeak:before {
  content: "\e084";
}
.socicon-teamviewer:before {
  content: "\e085";
}
.socicon-technorati:before {
  content: "\e086";
}
.socicon-telegram:before {
  content: "\e087";
}
.socicon-tripadvisor:before {
  content: "\e088";
}
.socicon-tripit:before {
  content: "\e089";
}
.socicon-triplej:before {
  content: "\e08a";
}
.socicon-tumblr:before {
  content: "\e08b";
}
.socicon-twitch:before {
  content: "\e08c";
}
.socicon-twitter:before {
  content: "\e08d";
}
.socicon-uber:before {
  content: "\e08e";
}
.socicon-ventrilo:before {
  content: "\e08f";
}
.socicon-viadeo:before {
  content: "\e090";
}
.socicon-viber:before {
  content: "\e091";
}
.socicon-viewbug:before {
  content: "\e092";
}
.socicon-vimeo:before {
  content: "\e093";
}
.socicon-vine:before {
  content: "\e094";
}
.socicon-vkontakte:before {
  content: "\e095";
}
.socicon-warcraft:before {
  content: "\e096";
}
.socicon-wechat:before {
  content: "\e097";
}
.socicon-weibo:before {
  content: "\e098";
}
.socicon-whatsapp:before {
  content: "\e099";
}
.socicon-wikipedia:before {
  content: "\e09a";
}
.socicon-windows:before {
  content: "\e09b";
}
.socicon-wordpress:before {
  content: "\e09c";
}
.socicon-wykop:before {
  content: "\e09d";
}
.socicon-xbox:before {
  content: "\e09e";
}
.socicon-xing:before {
  content: "\e09f";
}
.socicon-yahoo:before {
  content: "\e0a0";
}
.socicon-yammer:before {
  content: "\e0a1";
}
.socicon-yandex:before {
  content: "\e0a2";
}
.socicon-yelp:before {
  content: "\e0a3";
}
.socicon-younow:before {
  content: "\e0a4";
}
.socicon-youtube:before {
  content: "\e0a5";
}
.socicon-zapier:before {
  content: "\e0a6";
}
.socicon-zerply:before {
  content: "\e0a7";
}
.socicon-zomato:before {
  content: "\e0a8";
}
.socicon-zynga:before {
  content: "\e0a9";
}
